import React, { useEffect } from "react";
import small1 from "../../Images/TrendingJewellery/smallBox1.png";
import small2 from "../../Images/TrendingJewellery/smallBox2.png";
import small3 from "../../Images/TrendingJewellery/smallBox3.png";
import small4 from "../../Images/TrendingJewellery/smallBox4.png";
import large1 from "../../Images/TrendingJewellery/largeBox.png";

import ComingSoonImage from "../../CominSoonImage/ComingSoonImage";
import { Link, useNavigate } from "react-router-dom";

export default function TrendingJewellery() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  const navigate = useNavigate();
  return (
    <div className="trendingJewelleryMain">
      <div className="trendingJewellerySmallMain">
        {/* <div className="trendingJewellerySmallItems"> */}
        {/* <Link to="/category/ring"> */}
        <Link to="gender/female/ring/silver">
          <div data-aos="fade-down" data-aos-offset="100">
            <img
              data-aos="zoom-in"
              className="trendingJewellerySmallItems"
              src={small1}
              alt="Product Images"
            />
          </div>
        </Link>
        {/* </Link> */}
        {/* <Link to="/category/pendant"> */}
        <Link to="metal/silver/Bracelet">
          <div data-aos="fade-down" data-aos-offset="100">
            <img
              data-aos="zoom-in"
              className="trendingJewellerySmallItems"
              src={small2}
              alt="Product Images"
            />
          </div>
        </Link>
        {/* </Link> */}
        <Link to="gender/male/ring/silver">
          <div data-aos="fade-down" data-aos-offset="100">
            <img
              data-aos="zoom-in"
              className="trendingJewellerySmallItems"
              src={small3}
              alt="Product Images"
            />
          </div>
        </Link>
        <Link to="metal/silver/Chain">
          <div data-aos="fade-down" data-aos-offset="100">
            <img
              data-aos="zoom-in"
              className="trendingJewellerySmallItems"
              src={small4}
              alt="Product Images"
            />
          </div>
        </Link>

        {/* </div> */}
        {/* <div className="trendingJewellerySmallItems"></div>
        <div className="trendingJewellerySmallItems"></div>
      <div className="trendingJewellerySmallItems"></div> */}
      </div>
      {/* <div className="trendingJewelleryBig"> */}
      <Link to={`/metal/silver/${encodeURIComponent("Pendant Set")}`}>
        {/* <div data-aos="zoom-in" data-aos-offset="100"> */}
        <div data-aos="zoom-in" data-aos-offset="0">
          <img
            data-aos="zoom-in"
            className="trendingJewelleryBig"
            src={large1}
            alt="Product Images"
          />
        </div>
      </Link>
      {/* </div> */}
    </div>

    // <div className="trendingJewelleryMainNew">
    //   {/* <div className="trendingJewelleryItem"> */}
    //   <img
    //     data-aos="zoom-in"
    //     className="trendingJewelleryItemNew"
    //     src={halfGram}
    //     alt="Product Images"
    //     onClick={() => navigate("/category/coin")}
    //   />
    //   <img
    //     data-aos="zoom-in"
    //     className="trendingJewelleryItemNew"
    //     src={threeGram}
    //     alt="Product Images"
    //     onClick={() => navigate("/category/coin")}
    //   />
    //   <img
    //     data-aos="zoom-in"
    //     className="trendingJewelleryItemNew"
    //     src={oneGram}
    //     alt="Product Images"
    //     onClick={() => navigate("/category/coin")}
    //   />
    //   <img
    //     data-aos="zoom-in"
    //     className="trendingJewelleryItemNew"
    //     src={fiveGram}
    //     alt="Product Images"
    //     onClick={() => navigate("/category/coin")}
    //   />
    //   <img
    //     data-aos="zoom-in"
    //     className="trendingJewelleryItemNew"
    //     src={twoGram}
    //     alt="Product Images"
    //     onClick={() => navigate("/category/coin")}
    //   />
    //   <img
    //     data-aos="zoom-in"
    //     className="trendingJewelleryItemNew"
    //     src={tenGram}
    //     alt="Product Images"
    //     onClick={() => navigate("/category/coin")}
    //   />
    //   {/* </div> */}

    //   {/* <div className="trendingJewelleryItem"></div>
    //   <div className="trendingJewelleryItem"></div>
    //   <div className="trendingJewelleryItem"></div>
    //   <div className="trendingJewelleryItem"></div>
    //   <div className="trendingJewelleryItem"></div> */}
    // </div>
  );
}
