import React from "react";
import "../Components Styles/Heading.css";
import { useNavigate } from "react-router-dom";

export default function UpperTagLine() {
  const navigate = useNavigate();
  return (
    <div className="subscribeNow">
      {/* <span>
        Monthly Installment Scheme (Pay 10 installments, get 100% off on the
        11th installments <span /> */}
      <span>
        ⭐ Don't miss out on our exclusive limited edition silver collection{" "}
        <span />
        <span
          onClick={() => {
            navigate("/limitededition/Limited Edition");
          }}
          // className="coming-soon"
          id="subscribeNowLink"
        >
          (Discover it today!) ⭐
        </span>
      </span>
    </div>
  );
}
