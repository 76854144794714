import React from "react";
// import popUpImage from "../../Images/featuredImage4.png";
import popUpImage from "../../Images/ganesh/Email-Signup.jpg";
// import popUpImage from "../../Images/MobileCarousalImages/MobileCarousal2.png";
import { useState } from "react";
import { useEffect } from "react";
import { a1 } from "../../Api/RootApiPath";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../AlertMessages/AlertMessage";

export default function BeMemberPopup({ buttonHovered }) {
  const [allCsData, setAllCsData] = useState([]);
  const [email, setEmail] = useState("");
  const [dataRcvd, setDataRcvd] = useState(false);
  const [serverError, setServerError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // window.scrollTo(0, 0);
    fetchAllCs();
  }, []);
  const fetchAllCs = async () => {
    fetch(a1)
      .then((res) => res.json())
      .then((result) => setAllCsData(result.data));
    setDataRcvd(true);
  };
  const checkUserExist = () => {
    if (allCsData.filter((x) => x.customer_login_id === email).length !== 0) {
      alert("Email Already Exists, Please Login or use another email"),
        //   setServerError(true);
        navigate(`/customerloginpage?emailRcvd=${email}`);
    } else {
      navigate(`/customersignuppage?emailRcvd=${email}`);
    }
  };

  return (
    <div className="homePagePopUpMainBox">
      {/* {serverError ? (
        <AlertMessage
          message="Email Already Exists, Please Login or use another email !"
          type="error"
        />
      ) : null} */}
      {/* <h2 style={{ marginBottom: "0" }}> Shine, Invest, Prosper.</h2> */}
      <h2 style={{ marginTop: "5px", marginBottom: "20px" }}>
        {" "}
        BIGGEST SALE FOR SILVER JEWELLERY! SHOP NOW
      </h2>
      {/* <h2 style={{ marginTop: "5px", marginBottom: "20px" }}> Today! </h2> */}
      {/* <p>
        Step into a world of Ganpati-inspired decor that exudes charm and
        positivity.
      </p> */}
      <img className="popUpAdImage" src={popUpImage} alt="popup Ad Image" />
      <div className="homePagePopupSignUpBox">
        <input
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email Address"
        />
        {dataRcvd ? (
          <button
            onMouseEnter={() => buttonHovered(true)}
            onMouseLeave={() => buttonHovered(false)}
            onClick={() => checkUserExist()}
          >
            {" "}
            SIGN UP{" "}
          </button>
        ) : null}
      </div>
      <p style={{ padding: "0px" }}>
        As a member, you'll also enjoy the added perk of
        <p style={{ marginTop: "0" }}>
          <strong> NO DELIVERY CHARGES </strong>and other{" "}
          <strong> EXCLUSIVE BENEFITS, </strong>
        </p>
      </p>
      {/* <p className="homePagePopupConditionsMainLine">
        By Continuing, I agree to{" "}
        <em
          style={{ color: "green" }}
          onClick={() => navigate("/termsandconditions")}
          className="homePagePopupTermsAndConditionsLine"
        >
          Terms of Use{" "}
        </em>{" "}
        &{" "}
        <em
          style={{ color: "green" }}
          onClick={() => navigate("/privacypolicy")}
          className="homePagePopupTermsAndConditionsLine"
        >
          Privacy Policy
        </em>
      </p> */}
    </div>
  );
}
